import React, { Component } from 'react';
import { Icon } from 'semantic-ui-react';
import MenuLink from './menu-link';
import globeSVG from '../../images/globe.svg';
import SiteSearch from './search';

import {
  MobileIcon,
  MobileGlobeIcon,
  Globe,
  MobileGlobe,
  NavbarMenu,
  Logo,
  Wrapper,
  StyledMiddleLinks,
  NavbarLink,
} from './components';


export default class Navbar extends Component {
  constructor() {
    super();

    this.state = {
      mobileMenuShow: false,
      isMobile: false,
    };

    this.handleWindowResize = this.handleWindowResize.bind(this);
    this.renderNavBar = this.renderNavBar.bind(this);
    this.mobileMenuToggle = this.mobileMenuToggle.bind(this);
    this.closeMobileMenue = this.closeMobileMenu.bind(this);
    this.renderMobileIcons = this.renderMobileIcons.bind(this);
    this.renderLogo = this.renderLogo.bind(this);
  }

  componentDidMount() {
    this.setState({
      // eslint-disable-next-line no-undef
      isMobile: window.innerWidth < 675,
    });
    // eslint-disable-next-line no-undef
    window.addEventListener('resize', this.handleWindowResize);
  }

  mobileMenuToggle() {
    const { mobileMenuShow: mobileMenuState } = this.state;
    this.setState({ mobileMenuShow: !mobileMenuState });
  }

  closeMobileMenu() {
    this.setState({ mobileMenuShow: false });
  }

  handleWindowResize() {
    // eslint-disable-next-line no-undef
    this.setState({ isMobile: window.innerWidth < 675 });
  }

  renderNavBar(display) {
    const links = [
      { text: 'Home', href: '/' },
      { text: 'About', href: '/about' },
      { text: 'Resources', href: '/resources' },
      { text: 'Countries', href: '/countries' },

    ];
    return (
      <StyledMiddleLinks position="left" style={{ display }}>
        {
          links
            .map((link, i) => {
              const key = `${link.href}-${i}`;
              return (
                <NavbarLink
                  key={key}
                  onClick={this.closeMobileMenu}
                  href={link.href}
                >
                  {link.text}
                </NavbarLink>
              );
            })
        }
      </StyledMiddleLinks>
    );
  }

  renderMobileIcons(isMobile, mobileMenuShow) {
    return (
      <>
        <MobileIcon isMobile={isMobile} role="button" onClick={() => { this.mobileMenuToggle(); }}>
          {!mobileMenuShow && <Icon size="large" name="bars" />}
          {mobileMenuShow && <Icon size="large" name="times" />}
        </MobileIcon>
        <MobileGlobeIcon isMobile={isMobile}>
          <span><MobileGlobe alt="globe icon" src={globeSVG} /></span>
        </MobileGlobeIcon>
      </>
    );
  }


  renderLogo() {
    return (
      <Logo>
        <span><Globe alt="globe icon" src={globeSVG} /></span>
        <MenuLink name="home" href="/" onClick={this.closeMobileMenu}>
          <strong>Merchants of Pearls</strong>
        </MenuLink>
      </Logo>
    );
  }

  render() {
    const { isMobile, mobileMenuShow } = this.state;
    let display = 'flex';
    if (isMobile) {
      display = mobileMenuShow ? 'block' : 'none';
    }

    return (
      <Wrapper id="home">
        <NavbarMenu stackable secondary pointing fixed="top">
          {this.renderLogo()}
          {this.renderNavBar(display)}
          <SiteSearch display={display} />
          {this.renderMobileIcons(isMobile, mobileMenuShow)}
        </NavbarMenu>
      </Wrapper>
    );
  }
}
