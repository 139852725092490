import { Menu } from 'semantic-ui-react';
import styled from 'styled-components';
import theme from '../../styles/theme';
import MenuLink from './menu-link';

export const MobileIcon = styled.div`
  display: ${(props) => (props.isMobile ? 'block' : 'none')};
  position: absolute;
  top: 15px;
  right: 10px;
`;

export const NavbarLink = styled(MenuLink)``;

export const MobileGlobeIcon = styled.div`
  display: ${(props) => (props.isMobile ? 'block' : 'none')};
  position: absolute;
  top: 15px;
  left: 10px;
`;

export const Globe = styled.img`
  height: 22px;
  margin-top: 15px;
  margin-right: -5px;
`;

export const MobileGlobe = styled.img`
  height: 22px;
  margin-top: -5px;
`;

export const NavbarMenu = styled(Menu)`
  display: 'flex';
  justify-content: space-between;
  align-items: center;

  padding-right: 30px;
  padding-left: 30px;
  border: none !important;
  color: ${theme.colors.black};
  background-color: ${theme.colors.white} !important;
`;

export const NavbarMenuMobile = styled(Menu.Menu)`
  background: transparent;
`;

export const SearchBarWrapper = styled(NavbarMenuMobile)`
  padding-top: 6px;
`;

export const StyledMiddleLinks = styled(NavbarMenuMobile)`
  margin-left: 60px !important;

  ${theme.breakpoints.tablet} {
    margin-left: 0 !important;
  }
`;

export const Logo = styled(Menu.Menu)`
  margin-right: -60px !important;
  ${theme.breakpoints.mobile} {
    display: none !important;
  }
`;

export const SearchBar = styled(MenuLink)``;

export const Wrapper = styled.div``;
