import React from 'react';
import PropTypes from 'prop-types';
import { Link } from 'gatsby';
import { Menu } from 'semantic-ui-react';
import styled from 'styled-components';
import theme from '../../styles/theme';


const StyledGatsbyLink = styled(Link)`
  color: ${theme.colors.black};
  font-weight: 200;
  font-size: 16px;
  padding: 5px 0;
  :hover {
    color: ${theme.colors.lightGray};
  }
  ${theme.breakpoints.mobile} {
    margin: auto;
    font-size: 27px;
    font-weight: 400;
  }
`;
const StyledLink = styled.a`
  color: ${theme.colors.black};
  font-weight: 100;
  font-size: 16px;
  padding: 5px 0;
  :hover {
    color: ${theme.colors.lightGray};
  }
  ${theme.breakpoints.mobile} {
    margin: auto;
    font-size: 27px;
    font-weight: 400;
  }
`;

const NavbarItem = styled(Menu.Item)`
  background: none !important;
  ${theme.breakpoints.mobile}{
    border-top: 0.5px solid white !important;
  }
`;


const MenuLink = ({
  children, href, onClick, external,
}) => (
  <NavbarItem>
    {external && !href && <StyledLink onClick={() => onClick && onClick()}>{children}</StyledLink>}
    {!external && href && <StyledGatsbyLink to={href}>{children}</StyledGatsbyLink>}
    {external
      && href
      && (
      <StyledLink
        onClick={() => onClick && onClick()}
        href={href}
      >
        {children}
      </StyledLink>
      )}
  </NavbarItem>
);

MenuLink.defaultProps = {
  external: false,
  onClick: null,
  href: '',
};

MenuLink.propTypes = {
  children: PropTypes.node.isRequired,
  href: PropTypes.string,
  onClick: PropTypes.func,
  external: PropTypes.bool,

};
export default MenuLink;
