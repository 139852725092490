export default {
  colors: {
    primary: '#000',
    secondary: '#fff',
    lighterGray: '#b2bec3',
    lightGray: '#636e72',
    lightestGray: '#f9fafb',
    black: '#000',
    white: '#fff',
    contentText: '#323232',
  },

  breakpoints: {
    mobile: '@media screen and (max-width: 420px)',
    tablet: '@media screen and (max-width: 675px)',
  },
  font: {
    default: '"Roboto",Helvetica,Arial,sans-serif',
  },
};
