import React, { useState } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { Search } from 'semantic-ui-react';
import { useFlexSearch } from 'react-use-flexsearch';
import { graphql, useStaticQuery, navigate } from 'gatsby';
import theme from '../../styles/theme';
import { SearchBarWrapper } from './components';

const StyledSeach = styled(Search)`
    min-width: 300px;
  input {
    min-width: 300px;
    ${theme.breakpoints.mobile} {
      min-width: 300px;
    }
  }

  ${theme.breakpoints.mobile} {
    min-width: 100%;
  }
`;

const SiteSearch = ({ display }) => {
  const [query, setQuery] = useState('');
  const { localSearchPages } = useStaticQuery(
    graphql`
      query {
        localSearchPages {
          index
          store
        }
      }
    `,
  );
  const { index, store } = localSearchPages;
  const results = useFlexSearch(query, index, JSON.parse(store));

  const handleSelection = (e, { result }) => {
    navigate(`/countries/${result.path}`);
  };

  return (
    <SearchBarWrapper position="right" style={{ display }}>
      <StyledSeach
        size="small"
        fluid
        onResultSelect={handleSelection}
        onSearchChange={(e, { value }) => setQuery(value)}
        value={query}
        results={results}
      />
    </SearchBarWrapper>
  );
};

SiteSearch.propTypes = {
  display: PropTypes.string.isRequired,
};

export default SiteSearch;
