import React from 'react';
import styled from 'styled-components';
import Link from 'gatsby-link';
import { Icon, Divider } from 'semantic-ui-react';
import theme from '../styles/theme';

const Wrapper = styled.footer`
  display: flex;
  padding: 30px;
  margin-top: 30px;
  background: ${theme.colors.lightestGray};
  color: ${theme.colors.lightGray};
  @media screen and (max-width: 675px) {
    flex-direction: column;
  }
`;

const Address = styled.div`
  font-size: 11px;
  text-align: left;
  margin-bottom: 10px;
  p {
    margin: 0 !important;
  }

  h4 {
    margin: 0 !important;
  }
`;

const Third = styled.div`
  padding: 20px;
  flex: 1;
  text-align: center;
  padding-top: 40px;
  :nth-of-type(2) {
    padding-top: 0px;
  }
  ${theme.breakpoints.tablet}{
    :nth-of-type(2) {
      order: -1;
    }
  }
`;

const FooterLink = styled(Link)`
  padding: 10px;
  color: ${theme.colors.lightGray};

  :hover {
    color: ${theme.colors.lighterGray};
  }
`;

const Links = styled.div`
  margin-bottom: 30px;
  margin-top: 20px;
`;

const Attribution = styled.p`
  margin: 0;
  font-size: 12px;
  :last-of-type {
    margin-top: 20px;
    font-style: italic;
  }
`;


const Footer = () => (
  <Wrapper>
    <Third>
      <Address>
        <h4>Contact</h4>
        <p>email: contact@merchantsofpearls.com</p>
      </Address>
    </Third>
    <Third>
      <Divider />
      <Links>
        <FooterLink to="/">Home</FooterLink>
        <FooterLink to="/about">About</FooterLink>
        <FooterLink to="/resources">Resources</FooterLink>
        <FooterLink to="/countries">Countries</FooterLink>
      </Links>
      <Attribution>
        This site built with
        {' '}

        <Icon name="heart" />
        by the hands and feet of the father
      </Attribution>
      <Attribution>
        &copy;
        {new Date().getFullYear()}
        {' '}
        Merchants of Pearls
      </Attribution>
    </Third>
    <Third>
      <Icon size="huge" name="facebook" />
      <Icon size="huge" name="twitter" />
      <Icon size="huge" name="instagram" />
    </Third>
  </Wrapper>
);
export default Footer;
