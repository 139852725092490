import styled from 'styled-components';
import theme from '../styles/theme';

const PageWrapper = styled.div`
  margin-top: 50px;
`;
const PageTitle = styled.h1`
  color: ${theme.colors.black};
  text-align: center;
  font-size: 46px;
  font-weight: 100;

  ${theme.breakpoints.mobile} {
    bottom: 50px;
    left: 35%;
  }
`;
const PageContent = styled.div`
  ${(props) => (props.full ? '' : 'max-width: 1000px')};
  margin: ${(props) => (props.top ? '-130px auto 50px' : '40px auto 20px')};
  font-size: 20px;
  font-family: "Libre Baskerville",serif;
  padding: 50px;
  background-color: ${(props) => (props.background ? props.background : 'white')};

  p {
    color: ${theme.colors.contentText};
    font-weight: 300;
    line-height: 32px;
    -webkit-font-smoothing: antialiased;
  }

  li {
    color: ${theme.colors.contentText};
    font-weight: 300;
    line-height: 32px;
    -webkit-font-smoothing: antialiased;
  }

  a {
    color: ${theme.colors.primary};
  }
  a:hover {
    color: ${theme.colors.lightGray};
  }

  ${theme.breakpoints.tablet} {
    margin-top: 40px;
    font-size: 18px;
    min-height: 250px;
    max-width: 100%;
    padding: 20px;
  }
`;
const PageImage = styled.div`
  min-height: ${(props) => (props.img ? '600px' : '200px')};
  background: url(${(props) => props.img});
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
  position: relative;
  z-index: -1;

  ${theme.breakpoints.tablet} {
    min-height: 250px;
    padding-top: 50px;
  }
`;

const PageSectionHeader = styled.h2`
`;

const Page = {
  Wrapper: PageWrapper,
  Title: PageTitle,
  Content: PageContent,
  Image: PageImage,
  SeciontHeader: PageSectionHeader,
};

export default Page;
